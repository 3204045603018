@import "variables";
@import "mixins";

.slider {
  position: relative;
  background-position: center;
  background-size: cover;
  transition: background-image 0.5s ease-out;
  overflow: hidden;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 5;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.3);

  //   @include respond-to(tablet) {
  //     background-color: rgba(0, 0, 0, 0.6);
  //   }
  // }

  &__content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(tablet) {
      height: 100%;
    }

    & > .social-list {
      display: none;
      margin-top: auto;
      padding-top: 2.92vh;

      @include respond-to(tablet) {
        display: flex;
      }

      @include respond-to(small_tablet) {
        width: 100%;
      }
    }
  }

  &__list-wrap {
    position: relative;
    width: 100%;
    margin-top: 3.7vh;

    @include respond-to(tablet) {
      margin-top: 3.41vh;
    }

    @include respond-to(small_tablet) {
      margin-top: 4.49vh;
    }
  }

  &__item {
    position: relative;
    height: 74vh;
    overflow: hidden;

    @include respond-to(tablet) {
      height: 36.13vh;
    }

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      height: 64vh;
    }

    @include respond-to(small_tablet) {
      height: auto;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    position: absolute;
    top: 50%;
    right: -2.865vw;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    width: 32.77vh;
    height: 68.8vh;
    padding: 5vh 2.5vh 3.24vh;
    box-sizing: border-box;
    background-image: url(../img/content/bg_phone_info.png);
    background-size: cover;
    transition: top 0.4s ease-in-out;

    @include respond-to(tablet) {
      position: fixed;
      left: 0;
      transform: initial;
      z-index: 105;
      display: none;
      width: 100%;
      height: 100vh;
      padding: 0;
      background-image: url(../img/content/bg_phone_info_mobile.jpg);
    }

    &.is-hide {
      top: 2000px;

      @include respond-to(tablet) {
        top: 0;
        display: flex;
      }
    }

    &-header {
      display: flex;
      align-items: center;

      @include respond-to(tablet) {
        padding: 3.9vh;
        box-sizing: border-box;
        background-color: $black;
      }

      @include respond-to(small_tablet) {
        padding: 5.99vh;
      }

      &::before {
        content: '';
        display: block;
        width: 0.729vw;
        height: 1.302vw;
        background-image: url(../img/general/icon_back.svg);
        background-size: cover;

        @include respond-to(tablet) {
          width: 1.36vh;
          height: 2.44vh;
        }

        @include respond-to(small_tablet) {
          width: 2.09vh;
          height: 3.74vh;
        }
      }
    }

    &-author {
      margin-left: 2.31vh;
    }

    &-name {
      font-size: 0.833vw;
      font-weight: 600;
      line-height: 1.146vw;

      @media only screen and (max-width: 1920px) and (max-height: 1079px) {
        font-size: 0.729vw;
        line-height: 1.042vw;
      }

      @include respond-to(tablet) {
        font-size: 1.5625vh;
        line-height: 2.14vh;
      }

      @include respond-to(small_tablet) {
        font-size: 2.39vh;
        line-height: 3.29vh;
      }
    }

    &-position {
      margin-top: 0.46vh;
      font-size: 1.11vh;
      line-height: 0.885vw;

      @media only screen and (max-width: 1920px) and (max-height: 1079px) {
        font-size: 0.521vw;
        line-height: 0.729vw;
      }

      @include respond-to(tablet) {
        margin-top: 0.48vh;
        font-size: 1.17vh;
        line-height: 1.66vh;
      }

      @include respond-to(small_tablet) {
        margin-top: 0.74vh;
        font-size: 1.79vh;
        line-height: 2.54vh;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 4.62vh;
      padding: 0 0.365vw;

      @include respond-to(tablet) {
        margin-top: 2.44vh;
        padding: 3.9vh;
      }

      @include respond-to(small_tablet) {
        margin-top: 0;
        padding: 3.74vh 5.99vh;
      }
    }

    &-avatar {
      align-self: flex-end;
      width: 1.927vw;
      height: 1.927vw;
      border-radius: 100%;
      overflow: hidden;

      @include respond-to(tablet) {
        width: 3.61vh;
        height: 3.61vh;
      }

      @include respond-to(small_tablet) {
        width: 5.54vh;
        height: 5.54vh;
      }
    }

    &-message {
      max-height: 90%;
      margin-top: 0.92vh;
      padding: 1.66vh;
      border-radius: 20px;
      color: $black;
      background-color: #8ad7f0;
      overflow: hidden;

      @media only screen and (max-width: 1920px) and (max-height: 1079px) {
        max-height: 80%;
      }

      @include respond-to(tablet) {
        margin-top: 0.97vh;
        padding: 1.95vh;
      }

      @include respond-to(small_tablet) {
        margin-top: 1.49vh;
        padding: 2.99vh;
      }

      .text {
        display: none;
        margin-top: 0;

        &.is-show {
          display: block;
        }
      }
    }

    .btn-close {
      position: absolute;
      top: -2.77vh;
      right: -2.77vh;

      @include respond-to(tablet) {
        display: none;
      }
    }

    .social-list {

      @include respond-to(tablet) {
        display: none;
      }
    }
  }

  &__all-photos,
  &__show-info {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.302vw;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    cursor: pointer;

    @include respond-to(tablet) {
      font-size: 0;
    }

    &::before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 1.302vw;
      background-size: cover;

      @include respond-to(tablet) {
        margin-right: 0;
      }

      @include respond-to(tablet) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__all-photos {

    &::before {
      background-image: url(../img/general/icon_grid_photos.svg);
    }
  }

  &__show-info {
    min-width: 21.729vw;

    @include respond-to(tablet) {
      min-width: initial;
    }

    &::before {
      background-image: url(../img/general/icon_info.svg);

      @include respond-to(tablet) {
        margin-right: 1.46vh;
      }
    }

    &.is-hide {

      @include respond-to(tablet) {
        position: fixed;
        bottom: 2.92vh;
        left: 50%;
        transform: translateX(-50%);
        z-index: 110;
        font-size: 1.36vh;
        line-height: 1.95vh;
      }

      @include respond-to(small_tablet) {
        left: 0;
        bottom: 4.49vh;
        transform: initial;
        justify-content: center;
        width: 100%;
        padding-right: 5.99vh;
        padding-left: 5.99vh;
        box-sizing: border-box;
        font-size: 2.09vh;
        line-height: 2.99vh;
      }
    }
  }

  &__pagination {
    margin-top: 0.92vh;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.250vw;

    @include respond-to(tablet) {
      margin-top: 0.97vh;
      font-size: 2.34vh;
      line-height: 3.32vh;
    }

    @include respond-to(small_tablet) {
      margin-top: 1.79vh;
      font-size: 2.69vh;
      line-height: 3.74vh;
    }
  }

  .nav-list {
    margin-top: 3.7vh;

    @include respond-to(tablet) {
      margin-top: 3.9vh;
    }

    @include respond-to(small_tablet) {
      justify-content: center;
      margin-top: 5.24vh;
    }

    &__item {

      &:first-child {

        @include respond-to(small_tablet) {
          width: 100%;
        }
      }

      &:not(:first-child) {

        @include respond-to(small_tablet) {
          margin-top: 5.99vh;
        }
      }

      &:last-child {

        @include respond-to(small_tablet) {
          margin-left: 3.74vh;
        }
      }
    }
  }
}

.slick {

  &-arrow {
    border: none;
    background: none;
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    width: 2.396vw;
    height: 4.167vw;
    background-image: url(../img/general/icon_arrow.svg);
    background-size: cover;

    @include respond-to(tablet) {
      width: 2.24vh;
      height: 3.95vh;
    }

    &:focus {
      outline: none;
    }
  }

  &-next {
    right: -10.417vw;

    @include respond-to(tablet) {
      right: -4.88vh;
    }

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      right: -5.88vh;
    }
  }

  &-prev {
    left: -10.417vw;
    transform: translateY(-50%) rotate(180deg);

    @include respond-to(tablet) {
      left: -4.88vh;
    }

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      left: -5.88vh;
    }
  }
}

.photo-list {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;

  &.is-show {
    display: flex;
  }

  &__item {
    position: relative;
    width: 20%;
    height: calc(100vh / 3);
    cursor: pointer;

    @include respond-to(tablet) {
      width: calc(100% / 3);
      height: calc(100vh / 5);
    }

    &:hover {

      .photo-list__item-desc {
        display: block;

        @include respond-to(tablet) {
          display: none;
        }
      }
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    &-desc {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      padding: 4.62vh 1.563vw;
      box-sizing: border-box;
      background-color: rgba(102, 102, 102, 0.8);
    }
  }

  .btn-close {
    position: fixed;
    top: 0.92vh;
    right: 0.92vh;
    z-index: 110;
  }
}
