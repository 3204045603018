@import "variables";
@import "mixins";

.video {
  color: $black;
  background-color: $white;

  &__link {
    margin-top: 2.31vh;
    text-align: center;

    @include respond-to(tablet) {
      margin-top: 0.97vh;
    }

    @include respond-to(small_tablet) {
      padding: 0 2.99vh;
    }

    .btn {

      @include respond-to(tablet) {
        width: auto;
      }

      @include respond-to(small_tablet) {
        width: 100%;
      }

      @include respond-to(fablet) {
        font-size: 11px;
      }

      @include respond-to(small_phone) {
        font-size: 10px;
      }
    }
  }

  &__content {
    margin-top: 2.31vh;

    @include respond-to(tablet) {
      margin-top: 2.97vh;
    }
  }

  &__poster {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background-image: url(../img/content/bg_poster_video.jpg);
    background-size: cover;
    background-position: center;
    cursor: pointer;

    &.is-play {

      background-image: none;

      iframe {
        z-index: 10;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .container {
    width: 73%;
    max-width: 1440px;

    @media only screen and (max-width: 1920px) and (max-height: 1079px) {
      width: 72%;
    }

    @include respond-to(tablet) {
      width: 80%;
    }

    @include respond-to(small_tablet) {
      width: 100%;
    }
  }

  .section__title {
    margin-top: 0;
  }
}
