@import "../variables";
@import "../mixins";

.social-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--row {
    flex-direction: row;
    justify-content: space-between;

    @include respond-to(small_tablet) {
      padding-left: 2.99vh;
      padding-right: 2.99vh;
      box-sizing: border-box;
    }

    .social-list__item {

      &:not(:first-child) {
        margin-left: 1.823vw;
        margin-top: 0;

        @include respond-to(tablet) {
          margin-left: 9vh;
        }

        @include respond-to(small_tablet) {
          margin-left: 4vh;
        }
      }
    }
  }

  &__item {
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 35px;
    }
  }

  .social-likes__counter {
    font-size: 0;
  }
}