@import "variables";
@import "mixins";

.choose-galaxy {
  color: $black;
  background-color: $white;

  @include respond-to(tablet) {
    padding: 4.62vh 2.99vh;
  }

  &__content {
    display: flex;
    margin-top: 8.33vh;

    @include respond-to(tablet) {
      margin-top: 4.49vh;
    }
  }

  &__item {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% / 3);

    &:not(:first-child) {
      margin-left: 20px;
    }

    .btn {
      font-size: 1.56vh;

      @include respond-to(tablet) {
        font-size: 14px;
      }

      @include respond-to(fablet) {
        font-size: 12px;
      }
    }
  }

  &__img {
    height: auto;

    @include respond-to(tablet) {
      height: 59.97vh;
      max-height: 400px;
    }

    img {

      @include respond-to(tablet) {
        height: 100%;
        object-fit: contain;
      }
    }

    &.fold {
      width: 65%;
      max-width: 337px;

      @include respond-to(tablet) {
        width: 100%;
        max-width: initial;
      }
    }

    &.note {
      width: 50%;
      max-width: 262px;

      @include respond-to(tablet) {
        width: 100%;
        max-width: initial;
      }
    }

    &.galaxy-s {
      width: 48%;
      max-width: 251px;

      @include respond-to(tablet) {
        width: 100%;
        max-width: initial;
      }
    }
  }

  &__name {
    margin-top: 5.55vh;
    font-size: 1.406vw;
    line-height: 1.979vw;
    font-weight: 600;
    text-align: center;

    @include respond-to(tablet) {
      margin-top: 4.49vh;
      font-size: 3.59vh;
      line-height: 5.04vh;
    }
  }

  .container {

    @include respond-to(tablet) {
      width: 100%;
    }
  }

  .btn {
    margin-top: 2.77vh;

    @include respond-to(tablet) {
      width: auto;
      margin-top: 2.24vh;
      padding-right: 5vw;
      padding-left: 5vw;
    }
  }

  .slick {

    &-arrow {
      top: auto;
      bottom: 7vh;
      transform: translateY(0);
      background-image: url(../img/general/icon_arrow_black.svg);
    }

    &-next {
      right: 0;
    }

    &-prev {
      left: 0;
      transform: translateY(0) rotate(180deg);
    }
  }
}
