@import "variables";
@import "mixins";

.about-project {
  background-image: url(../img/content/bg_project_about.jpg);
  background-position: center;
  background-size: cover;

  @include respond-to(small_tablet) {
    padding-left: 2.99vh;
    padding-right: 2.99vh;
  }

  &__content {
    margin-top: 14.35vh;
    padding-left: 6.250vw;
    padding-right: 6.250vw;
    box-sizing: border-box;

    @include respond-to(tablet) {
      margin-top: 15.13vh;
      padding-left: 0;
      padding-right: 0;
    }

    @include respond-to(small_tablet) {
      margin-top: 4.49vh;
    }
  }

  &__desc {
    display: flex;
    margin-top: 6vh;

    @include respond-to(tablet) {
      margin-top: 5.85vh;
    }

    @include respond-to(small_tablet) {
      justify-content: center;
      margin-top: 5.24vh;
    }

    p {
      font-family: $samsungOne;
      font-size: 2.22vh;
      line-height: 3.14vh;

      @include respond-to(tablet) {
        font-size: 1.56vh;
        line-height: 2.14vh;
      }

      @include respond-to(small_tablet) {
        font-size: 2.54vh;
        line-height: 3.59vh;
      }

      @media only screen and (max-width: 1024px) and (orientation: landscape) {
        font-size: 2.22vh;
        line-height: 3.14vh;
      }

      &:not(:first-child) {
        margin-top: 3.7vh;

        @include respond-to(tablet) {
          margin-top: 3.9vh;
        }
      }
    }

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__col {
    width: 50%;

    @include respond-to(small_tablet) {
      display: none;
      width: 100%;
    }

    &:not(:first-child) {
      margin-left: 4.167vw;

      @media only screen and (max-width: 1920px) and (max-height: 1079px) {
        margin-left: 7.4vh;
      }

      @include respond-to(tablet) {
        margin-left: 3.9vh;
      }

      @include respond-to(small_tablet) {
        margin-left: 0;
      }
    }

    &.is-active {
      display: block;
    }
  }

  &__pagination {
    display: none;
    justify-content: center;
    margin-top: 9.74vh;

    @include respond-to(small_tablet) {
      display: flex;
    }

    p {
      width: 3.14vh;
      font-size: 1.75vh;
      line-height: 2.63vh;
      text-align: center;
      cursor: pointer;

      @include respond-to(small_tablet) {
        font-size: 2.54vh;
        line-height: 3.59vh;
      }

      &.is-active {
        font-weight: bold;
      }
    }
  }

  .section__title {
    margin-top: 0;
  }
}