// File for scss-vars.
// For example: main colors, paddings and so on.

// Device dimensions

$phone: 320px;
$xxs: 374px;
$xs: 479px;
$mobile: 639px;
$sm: 767px;
$md: 1024px;
$wd: 1279px;
$lg: 1439px;
$xxl: 1919px;

// Colors

$black: #000;
$white: #fff;

// Font size

$basic-font-size: 10px;
$samsungSharp: 'Samsung Sharp';
$samsungOne: 'Samsung One';
