/* Your custom fonts here */

@font-face {
  font-family: 'Samsung Sharp';
  src: url("../fonts/SamsungSharp/SamsungSharpSans-Regular.woff2") format("woff2"), url("../fonts/SamsungSharp/SamsungSharpSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Samsung Sharp';
  src: url("../fonts/SamsungSharp/SamsungSharpSans-Bold.woff2") format("woff2"), url("../fonts/SamsungSharp/SamsungSharpSans-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Samsung One';
  src: url("../fonts/SamsungOne/SamsungOneCyrillic-Regular.woff2") format("woff2"),
  url("../fonts/SamsungOne/SamsungOneCyrillic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
