@import "variables";
@import "mixins";

html,
body {
  width: 100%;
  min-height: 100%;
  font-size: $basic-font-size;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
ol,
ul,
input,
button,
textarea {
  padding: 0;
  margin: 0;
}

body {
  max-width: 2560px;
  margin: 0 auto;
  font-family: $samsungSharp, 'Helvetica', sans-serif;
  color: $white;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  &.is-inactive {
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.btn {
  display: inline-block;
  height: 4.62vh;
  padding-left: 1.563vw;
  padding-right: 1.563vw;
  border: 2px solid $white;
  border-radius: 40px;
  box-sizing: border-box;
  font-size: 1.66vh;
  font-weight: 600;
  line-height: 4.62vh;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: $white;

  @include respond-to(tablet) {
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }

  &--black {
    border-color: $black;
    color: $black;
  }

  &-close {
    border: none;
    background: none;
    width: 2.77vh;
    height: 2.77vh;
    background-image: url(../img/general/icon_close.svg);
    background-size: cover;
    cursor: pointer;

    @include respond-to(small_tablet) {
      width: 4.49vh;
      height: 4.49vh;
    }
  }
}

.title {
  font-size: 1.250vw;
  line-height: 1.563vw;

  @include respond-to(tablet) {
    font-size: 1.56vh;
    line-height: 2.14vh;
  }
}

.text {
  font-family: $samsungOne;
  font-size: 0.838vw;
  line-height: 1.15vw;

  @include respond-to(tablet) {
    font-size: 1.75vh;
    line-height: 2.63vh;
  }

  @include respond-to(small_tablet) {
    font-size: 2.69vh;
    line-height: 4.04vh;
  }

  &:not(:first-of-type) {
    margin-top: 1.042vw;

    @include respond-to(small_tablet) {
      margin-top: 5.99vh;
    }
  }

  &--small {
    font-size: 0.73vw;
    line-height: 1.05vw;

    @include respond-to(tablet) {
      font-size: 1.75vh;
      line-height: 2.63vh;
    }
  }
}

.logo {
  display: block;

  &-list {
    position: absolute;
    top: 4.62vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;

    @include respond-to(tablet) {
      position: relative;
      top: auto;
      left: auto;
      transform: initial;
      width: 100%;
    }
  }

  &--samsung {
    width: 165px;

    @include respond-to(desktop) {
      width: 102px;
    }
  }

  &--galaxy {
    width: 120px;

    @include respond-to(desktop) {
      width: 75px;
    }
  }
}

.section {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 4.62vh 0;
  box-sizing: border-box;
  background-color: $black;

  @include respond-to(hd) {
    height: auto;
  }

  @media only screen and (max-width: 1920px) and (max-height: 1079px) {
    height: auto;
  }

  @media only screen and (min-width: 1921px) and (max-height: 1239px) {
    height: auto;
  }

  &__title {
    margin-top: 2.31vh;
    font-size: 3.333vw;
    line-height: 4.688vw;
    text-align: center;

    @include respond-to(tablet) {
      margin-top: 2.44vh;
      font-size: 5.27vh;
      line-height: 7.81vh;
    }

    @include respond-to(small_tablet) {
      font-size: 4.49vh;
      line-height: 6.29vh;
    }
  }
}

.container {
  width: 73.958vw;
  margin: 0 auto;

  @media only screen and (max-width: 1920px) and (max-height: 1079px) {
    width: 65.958vw;
  }

  @include respond-to(tablet) {
    width: 85%;
  }

  @include respond-to(small_tablet) {
    width: 100%;
  }
}

.hashtag {
  height: auto;

  &--big {
    width: 80%;
    max-width: 1156px;
    margin-top: 1.85vh;

    @media only screen and (max-width: 1920px) and (max-height: 1079px) {
      width: 50%;
    }

    @include respond-to(tablet) {
      width: 90%;
      margin-top: 2.99vh;
    }

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      width: 70%;
    }

    @include respond-to(small_tablet) {
      width: 100%;
      margin-top: 4.49vh;
    }
  }

  &--small {
    width: 80%;
    max-width: 284px;
    margin: 0 auto;

    @include respond-to(tablet) {
      width: 60%;
    }
  }
}

.scroll-down {
  display: none;
  width: 3.51vh;
  height: 5.85vh;
  margin: 6vh auto 0;
  background-image: url(../img/general/icon_scroll_down.svg);
  background-size: cover;
  cursor: pointer;

  @include respond-to(tablet) {
    display: block;
  }

  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 7.77vh;
  }
}
