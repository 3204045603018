@import "variables";
@import "mixins";

.teaser {
  box-sizing: border-box;
  background-image: url(../img/content/bg_teaser.jpg);
  background-position: center;
  background-size: cover;

  @include respond-to(hd) {
    height: 100vh;
  }

  @media only screen and (max-width: 1919px) and (max-height: 1079px) {
    height: auto;
  }

  @include respond-to(tablet) {
    padding: 4.62vh 2.99vh;
    height: auto;
  }

  @include respond-to(small_tablet) {
    padding: 2.99vh;
    height: 100vh;
    background-image: url(../img/content/bg_teaser_mobile.jpg);
  }

  @include respond-to(small_phone) {
    height: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100%;

    &::after {
      content: '';
      display: block;
      width: 1px;
      padding-top: 35%;

      @include respond-to(tablet) {
        padding-top: 135%;
      }

      @media only screen and (max-width: 1024px) and (orientation: landscape) {
        padding-top: 90%;
      }

      @include respond-to(small_tablet) {
        padding-top: 35%;
      }

      @include respond-to(small_phone) {
        padding-top: 100%;
      }
    }
  }

  &__title {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-to(tablet) {
      flex-direction: column;
      margin-top: 2vh;
    }

    @include respond-to(small_tablet) {
      display: none;
    }

    .title {
      font-weight: normal;
      text-align: center;

      a {
        color: white
      }

      @include respond-to(tablet) {
        font-size: 2.09vh;
        line-height: 2.69vh;
      }

      span {

        @include respond-to(tablet) {
          font-weight: bold;
        }
      }
    }
  }

  &__sign {
    margin-top: 2.12vh;
    font-size: 2.22vh;
    line-height: 3.14vh;
    text-align: center;

    @include respond-to(tablet) {
      margin-top: 2.99vh;
      font-size: 1.95vh;
      line-height: 2.74vh;
    }

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      font-size: 2.69vh;
      line-height: 3.74vh;
    }

    @include respond-to(small_tablet) {
      margin-top: 4.49vh;
      font-size: 2.69vh;
      line-height: 3.74vh;
    }
  }

  .nav-list {

    @include respond-to(tablet) {
      display: none;
    }

    &__item {

      @include respond-to(tablet) {
        flex-grow: 1;
      }
      
      &:last-child {

        @include respond-to(tablet) {
          width: 100%;
          margin-top: 2.99vh;
          margin-left: 0;
        }
      }
    }
  }

  .social-list {
    position: absolute;
    top: 50%;
    right: 2.344vw;
    transform: translateY(-50%);

    @include respond-to(tablet) {
      position: relative;
      top: auto;
      right: auto;
      transform: initial;
      flex-direction: row;
      margin-top: 2.99vh;
    }

    @include respond-to(small_tablet) {
      display: none;
      width: 100%;
      justify-content: space-between;
    }

    &__item {

      &:not(:first-child) {

        @include respond-to(tablet) {
          margin-left: 9vh;
          margin-top: 0;
        }

        @include respond-to(small_tablet) {
          margin-left: 4vh;
        }
      }
    }
  }
}

.nav {

  &-list {
    display: flex;
    align-items: center;
    margin-top: 2.31vh;

    @include respond-to(tablet) {
      flex-wrap: wrap;
      margin-top: 3vh;
    }

    &.is-mobile {
      display: none;

      @include respond-to(tablet) {
        display: flex;
        
      }

      @include respond-to(small_tablet) {
        margin-top: 4.49vh;
      }
    }

    &__item {

      &:not(:first-child) {
        margin-left: 2.604vw;

        @include respond-to(tablet) {
          margin-left: 1.95vh;
        }
      }
    }
  }
}
